@media (max-width: 1024px) {
  .burger-container {
    position: fixed;
    display: block;
  }
  .nav-div {
    display: none;
  }

  .name-container {
    display: none;
  }
  nav {
    height: 80px;
    position: fixed;
    .burger-menu {
      top: -14px;
      right: 20px;
    }
  }
  .nav-background {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -3;
    background-color: $opaque-color;
  }
  .nav-animation {
    animation: navbar-intro 8s;
  }

  .project-section,
  .skills-container div {
    width: 90%;
    ul {
      width: 30%;
      text-align: center;
    }
    li {
      font-size: 1.1rem;
    }
  }
  .hero-text {
    width: 80vw;
    max-width: 550px;
  }
  .hero-text h2 {
    font-size: 2.4rem;
  }
  .personal-blurb p {
    width: 80%;
  }
  .name-container {
    display: none;
  }
  .name-mobile {
    display: block;
    font-size: 2rem;
    font-weight: 300;
    padding: 20px;
    position: absolute;
    left: 10%;
  }

  .contact-div {
    width: 80vw;
  }
  .contact-container {
    height: 93vh;
  }

  .blurb-div {
    p {
      padding: 30px;
    }
  }

  .arrow {
    // display: none;
    // top: 0;
    top: 30px;
    h4 {
      color: $opaque-color;
      letter-spacing: 20px !important;
    }
  }

  // .lowered-arrow {
  //   display: block;
  //   h4 {
  //     letter-spacing: 20px;
  //   }
  // }
}

@media (max-width: 820px) {
  .arrow h4 {
    font-size: 3.5rem !important;
  }
  .blurb-div {
    flex-direction: column;
    width: 100% !important;
    align-items: center;
    div {
      width: 100% !important;
    }
    &:nth-of-type(2) {
      img {
        order: 1;
      }
      div {
        order: 2;
      }
    }
  }
}

@media (max-width: 414px) {
  nav {
    height: 60px;
  }
  // .lowered-arrow {
  //   display: block;
  // }
  .arrow h4 {
    font-size: 2.5rem !important;
    letter-spacing: 5px !important;
    // margin-top: 50px;
  }
  .name-mobile {
    font-size: 1.5rem;
    left: 2%;
  }
  .hero-text h2 {
    font-size: 1.9rem;
  }
  .contact-div {
    margin: 110px auto;
  }
  .burger-container {
    right: 0;
  }
  .burger-nav-toggle {
    right: 17px;
  }
  .contact-container {
    height: 100vh;
    padding-top: 60px;
  }
  .about-container,
  .projects-container {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .project-section div li,
  .skills-container div li {
    font-size: 1rem;
  }
  .images-container img {
    width: 100%;
  }
  .blurb-div {
    flex-direction: column;
    width: 360px !important;
    align-items: center;
    &:nth-of-type(2) {
      img {
        order: 1;
      }
      div {
        order: 2;
      }
    }
    p {
      width: 50% !important;
    }
  }
}
