.fade-in {
  animation: fade-in 9s;
}

@keyframes fade-in {
  0% {
    margin-top: 50px;
    position: relative;
    z-index: -1;
    color: transparent;
  }
  75% {
    margin-top: 50px;
    color: transparent;
  }
}

@keyframes intro-animation {
  0% {
    background: $opaque-color;
    width: 0;
    left: 0;
  }
  50% {
    width: 110%;
    left: 0;
  }
  80% {
    background: $opaque-color;
  }
  100% {
    background: white;
    width: 0;
    left: 110%;
  }
}

@keyframes navbar-intro {
  0% {
    background: white;
    width: 0;
  }
  80% {
    width: 0;
    background: white;
  }
  100% {
    background: $opaque-color;
    width: 100vw;
  }
}
