$primary-color: #6074ac;
$secondary-color: #b1bddea1;
$opaque-color: #a8b6de;
$light-color: #89a0e0af;

* {
  margin: 0;
  padding: 0;
  font-family: "Work Sans", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: all 0.2s ease;
  &::selection {
    background-color: $primary-color;
    color: $opaque-color;
  }
  li {
    font-size: 1.1rem;
  }
  .App {
    overflow: hidden;
  }
  a,
  h1,
  h3 {
    user-select: none;
    color: black;
  }
  .link-out {
    color: $primary-color;
    i {
      color: #435891;
    }
  }
}
.name-mobile {
  display: none;
}

.arrow {
  text-align: center;
  position: relative;
  margin: auto;
  color: transparent;
  z-index: -1;
  top: 100px;
  transition: all 1s ease-out;
  h4 {
    padding: 15px;
    margin-bottom: 5px;
    font-size: 6rem;
    font-weight: 600;
    letter-spacing: 35px;
  }
  p {
    font-size: 1.3rem;
  }
}

.lowered-arrow {
  top: 30px;
  color: $opaque-color;
  transition: all 1s ease-out;
}

@import "./pages/navbar";
@import "./pages/hero";
@import "./pages/about";
@import "./pages/contact";
@import "./pages/projects";
@import "./burger";
@import "./name";
@import "./mediaQueries";
@import "./keyframes";
