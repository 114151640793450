.about-container {
  min-height: 100vh;
  position: relative;
  padding-bottom: 100px;
}

.personal-blurb {
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .blurb-heading {
    text-align: center;
    font-size: 1.2rem;
    color: $primary-color;
    margin-bottom: 40px;
  }
  .blurb-div {
    display: flex;
    width: 65%;
    max-width: 800px;
    margin-bottom: 20px;
    justify-content: center;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
    }
    p {
      font-size: 1rem;
      text-align: justify;
      line-height: 1.5;
      // width: 100%;
      min-width: 320px;
      margin: 15px;
    }
    img {
      width: 250px;
      border-radius: 5%;
      object-fit: cover;
    }
  }
}

.skills-container {
  padding-top: 20px;
  margin: 100px 0;
  h3 {
    width: fit-content;
    border-bottom: none;
    font-weight: 300;
    font-size: 1.5rem;
  }
  div {
    width: 65%;
    margin: 10px auto;
    display: flex;
    justify-content: space-evenly;
    li {
      list-style: none;
      padding-bottom: 5px;
      font-size: 1rem;
    }
  }
}

h3 {
  font-size: 1.3rem;
  width: 170px;
  text-align: center;
  letter-spacing: 2px;
  border-bottom: 1px solid black;
  margin: auto;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.resume {
  margin-top: 40px;
  color: $primary-color;
  display: block;
  text-align: center;
}
