.burger-menu-back,
.burger-menu {
  width: 20px;
  height: 20px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  margin-right: 1rem;
  transition: all 0.5s ease;
}
.burger-menu {
  transform: rotate(45deg);
  position: relative;
}

.burger-menu-back {
  transform: rotate(-135deg);
  position: absolute;
  cursor: pointer;
  top: -52px;
  right: 40px;
  z-index: -1;
}

.burger-container {
  display: none;
  position: fixed;
  width: 46px;
  height: 42px;
  right: 20px;
  top: 36px;
  cursor: pointer;
}

.burger-nav {
  position: absolute;
  top: 74px;
  right: -120px;
  transition: all 0.5s ease;
  a {
    background-color: $opaque-color;
    text-decoration: none;
    display: block;
    text-align: right;
    padding: 5px 10px;
    margin: 10px 0;
    font-size: 1.1rem;
  }
}

.burger-nav-toggle {
  right: 38px;
}
