.hero-container {
  height: 100vh;
  position: relative;
  transform: translateZ(1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-text {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding: 20px;
  z-index: 2;
  h2 {
    font-size: 3rem;
    font-weight: 200;
  }
}

.skip-intro {
  margin-top: 100px;
  background: none;
  border: none;
  color: $primary-color;
  border-bottom: 1px solid $secondary-color;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
}

.text-h2 {
  position: relative;
  width: fit-content;
  padding: 0 10px;
}

.intro-text {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -2;
  animation: intro-animation 1.4s cubic-bezier(0.65, 0.17, 0.6, 0.99) 0.2s;
}

.intro-text-2 {
  animation: intro-animation 1.4s cubic-bezier(0.65, 0.17, 0.6, 0.99) 1.6s;
}

.intro-text-3 {
  animation: intro-animation 1.2s cubic-bezier(0.65, 0.17, 0.6, 0.99) 3s;
}

.intro-text-4 {
  animation: intro-animation 1.2s cubic-bezier(0.65, 0.17, 0.6, 0.99) 4.2s;
}

.intro-text-5 {
  animation: intro-animation 1s cubic-bezier(0.65, 0.17, 0.6, 0.99) 5.6s;
}
