.projects-container {
  height: fit-content;
  margin-bottom: 100px;
  background-color: $secondary-color;
  h1 {
    text-align: center;
  }
}

.project-section {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 65%;
  .project {
    margin: 50px 0;
    border-radius: 15px;
    background-color: $secondary-color;
    padding: 20px;
    div:nth-of-type(2) {
      margin-top: 30px;
    }
    p {
      margin-top: 10px;
    }
    h3 {
      width: 100%;
      border-bottom: 2px solid black;
      font-size: 2rem;
      font-weight: 300;
    }
    .external-links {
      text-align: right;
      padding-top: 20px;
    }
    a {
      text-decoration: none;
    }
    i {
      padding: 0 8px;
      font-size: 1.2rem;
    }
  }
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  img {
    width: 50%;
    height: auto;
    max-width: 500px;
    padding: 5px;
    border-radius: 15px;
    transition: all 0.5s ease;
    object-fit: cover;
  }
}

.gif-essentials {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  img {
    width: 60%;
    max-width: 500px;
    padding: 5px;
    border-radius: 15px;
  }
}

.fa-medal {
  color: goldenrod;
  text-shadow: 0 0 4px black;
  font-size: 1.6rem !important;
  position: relative;
  left: 10px;
}

.essentials-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.winner,
.tech {
  text-align: center;
}
