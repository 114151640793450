.contact-container {
  background-color: $secondary-color;
  height: 100vh;
}

.contact-div {
  display: flex;
  margin: 150px auto;
  justify-content: center;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  width: 500px;
  height: 35vh;
  a {
    user-select: all;
    color: $primary-color;
  }
  i {
    font-size: 2rem;
  }
  p {
    font-size: 1.1rem;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 10px;
  }
  & + a {
    text-decoration: none;
  }
}

.work-together {
  border-bottom: none;
  position: relative;
  bottom: 10%;
  font-weight: 300;
  font-size: 1.6rem;
  width: fit-content;
  &:hover {
    color: $primary-color;
    cursor: pointer;
  }
}
