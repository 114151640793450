nav {
  height: 10vh;
  width: 100%;
  position: fixed;
  z-index: 4;
  font-family: "Syne Mono", monospace;
  font-size: 1.3rem;
}

.nav-div {
  width: 600px;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 45px;
  right: 30px;
  a {
    text-decoration: none;
    margin: 0 1rem;
    padding-bottom: 5px;
    display: block;
    width: auto;
    &:visited {
      color: black;
    }
    &:hover {
      border-bottom: 1px solid black;
    }
  }
}

.rotatedRight {
  position: absolute;
  width: 700px;
  right: -280px;
  top: 330px;
  transform: rotate(90deg);
  transition: all 0.4s ease;
}

.selectedLink {
  transform: rotate(-90deg);
  border-bottom: 1px solid black;
  transition: all 0.4s ease;
}
