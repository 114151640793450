.name-container {
  position: absolute;
  left: 5%;
  top: 20px;
  transition: all 0.8s ease;
  h1 {
    display: inline-block;
    margin: 8px;
    font-size: 2.5rem;
    font-weight: 200;
  }
}

.name {
  font-size: 2.7rem !important;
}

.underline {
  border-bottom: 1px solid black;
}

.centered {
  transform: rotate(90deg);
}

.rotatedLeft {
  position: absolute;
  left: -115px;
  top: 300px;
  transform: rotate(-90deg);
}
